import {
    Abstract
} from './Abstract';

export class Bank extends Abstract {
    constructor(session) {
        super('accountsreceivable/Bank', session);
        this.name = null;
        this.key_name = null;
        this.code_SAT = null;
        this.rfc = null;
    }
}