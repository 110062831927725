<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Cliente">
          <div class="grid formgrid p-fluid">
            <div class="col-12">
            <TabView>
            <TabPanel header="Informacion General">
              <Fieldset :toggleable="true" legend="Informacion General">
                <HelperDialog v-model="customerHelper" header="Buscar cliente" :headers="customerHeaders" :rows="entities" @selected="selectCustomer" />
                <BasicFormToolbar v-if="!view" @new="openNew" @save="saveCustomer" :actions="['new','save']" />
            <div class="p-fluid formgrid grid">
              <FormInputText wrapperClass="field col-2" label="Folio Cliente" @search="(customerHelper.visible=true)" :search="true" v-model="entity.id" :tooltip="'Seleccionar cliente '"/>
              <FormInputText wrapperClass="field col-2" label="Nombre" :valid="validate.validations.name" v-model="entity.name" />
              <FormInputText wrapperClass="field col-4" label="RFC" :valid="validate.validations.rfc" v-model="entity.rfc" />
              <FormInputText wrapperClass="field col-4" label="Direccion" :valid="validate.validations.address" v-model="entity.address" />
              <FormInputText wrapperClass="field col-4" label="Codigo Postal" :valid="validate.validations.postal_code" v-model="entity.postal_code" />
              <FormInputText wrapperClass="field col-4" label="Pais" :valid="validate.validations.country" v-model="entity.country" />
              <FormInputText wrapperClass="field col-4" label="Estado" :valid="validate.validations.state" v-model="entity.state" />
              <FormInputText wrapperClass="field col-4" label="Municipio" :valid="validate.validations.municipality" v-model="entity.municipality" />
              <FormInputText wrapperClass="field col-4" label="Colonia" :valid="validate.validations.suburb" v-model="entity.suburb" />
              <FormInputText wrapperClass="field col-4" label="E-mail" :valid="validate.validations.email" v-model="entity.email" />
              <FormInputText wrapperClass="field col-4" label="Telefono" :valid="validate.validations.phone" v-model="entity.phone" />
              <FormDropdownComplex :filterFields="['c_RegimenFiscal', 'Descripcion']" :valid="validate.validations.tax_regime" :labelInOption="'c_RegimenFiscal - Descripcion'" 
              :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-4'" label="Regimen Fiscal" v-model="entity.tax_regime" 
              :options="c_RegimenFiscal" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" :tooltip="'Se obtiene del catalogo del SAT: Regimen Fiscal'"/>
              <FormDropdownComplex :filterFields="['c_UsoCFDI', 'Descripcion']" :valid="validate.validations.use_cfdi" :labelInOption="'c_UsoCFDI - Descripcion'" 
              :labelInValue="'c_UsoCFDI - Descripcion'" :wrapperClass="'field col-4'" label="Uso CFDI" v-model="entity.use_cfdi" 
              :options="c_UsoCFDI" optionLabel="c_UsoCFDI" optionValue="c_UsoCFDI" :tooltip="'Se obtiene del catalogo del SAT: Uso CFDI'"/>
            </div>
          </Fieldset>
            </TabPanel>
            <TabPanel header="Contabilidad">
              <Fieldset :toggleable="true" legend="Contabilidad">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="saveAccounting" @list="openTable" :actions="['new','save','list']" />
                <div class="p-fluid formgrid grid">
                  <FormDropdownComplex :filterFields="['id_key', 'name']" :labelInOption="'id_key - name'" 
                  :labelInValue="'id_key - name'" :wrapperClass="'field col-4'" label="Cuenta Contable" v-model="entity.id_accounting_ledger_account" 
                  :options="accounts" optionLabel="id_key" optionValue="id" :tooltip="'Dar de alta en esta ruta: Catalogos/Contabilidad/Cuenta Contable'"/>
                  <FormInputText :readonly="true" wrapperClass="field col-8" v-model="accounting_ledger_account_name" :label="'Nombre Cuenta Contable'" />
                  <div v-if="!modal" class="col-12">
                    <Fieldset :toggleable="true" legend="Resultados">
                      <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true" :rowedit="true" 
                      @edited="edit" @deleted="deleteDialog = true"/>
                      <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
                    </Fieldset>
                  </div>
                </div>
              </Fieldset>
            </TabPanel>
            <TabPanel header="Referencias">
              <Fieldset :toggleable="true" legend="Referencias">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="saveReference" @list="openTable" :actions="['new','save','list']" />
                <div class="p-fluid formgrid grid">
                  <FormInputText :readonly="true" :wrapperClass="'field col-1'" v-model="reference.id" label="Referencia"/>
                  <FormInputText :readonly="true" :wrapperClass="'field col-5'" v-model="reference.name" label="Nombre de la Referencia"/>
                  <FormInputText :readonly="true" :wrapperClass="'field col-6'" v-model="reference.name" label="Nombre Completo de la Referencia"/>
                  <div v-if="!modal" class="col-12">
                    <Fieldset :toggleable="true" legend="Resultados">
                      <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true" :rowedit="true" 
                      @edited="edit" @deleted="deleteDialog = true"/>
                      <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
                    </Fieldset>
                  </div>
                </div>
              </Fieldset>
            </TabPanel>
            <TabPanel header="Cuenta bancaria">
              <Fieldset :toggleable="true" legend="Cuenta bancaria">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="saveBankAccount" :actions="['new','save']" />
                <div class="p-fluid formgrid grid">
                  <FormDropdown wrapperClass="field col-6"  v-model="bank.id_bank" :options="banks" :optionLabel="'name'" :optionValue="'id'" :label="'Banco'" />
                  <FormInputText :wrapperClass="'field col-6'" label="Clave Interbancaria" v-model="bank.interbank_key"/>
                  <div v-if="!modal" class="col-12">
                    <Fieldset :toggleable="true" legend="Resultados">
                      <BasicDatatable :rows="customerBanks" :headers="bankAccountHeaders" :rowaction="true" :rowdelete="true" :rowedit="true" 
                      @edited="editBankAccount" @deleted="deleteDialog = true"/>
                      <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
                    </Fieldset>
                  </div>
                </div>
              </Fieldset>
            </TabPanel>
          </TabView>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import {Bank} from "../../../models/cxp/Bank"
import {CustomerBankAccount} from "../../../models/comercial/CustomerBankAccount"
import FormDropdown from '../../../components/general/FormDropdown.vue';
import { Customer } from "../../../models/comercial/Customer";
import HelperDialog from '../../../components/general/HelperDialog.vue';
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
  satCatalogo,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { CustomerReference } from "../../../models/comercial/CustomerReference";
import { AccountingLedgerAccount } from '../../../models/contabilidad/AccountingLedgerAccount';
export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      open: false,
      reference: null,
      references: [],
      entity: null,
      entities: [],
      accounts: [],
      c_RegimenFiscal: [],
      c_UsoCFDI: [],
      bank: new CustomerBankAccount(this.session),
      banks:[],
      customerBanks:[],
      deleteDialog: false,
      customerHelper:{
        visible: false
      },
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "rfc" }),
        new Rule({ name: "address" }),
        new Rule({ name: "postal_code" }),
        new Rule({ name: "country" }),
        new Rule({ name: "state" }),
        new Rule({ name: "municipality" }),
        new Rule({ name: "suburb" }),
        new Rule({ name: "email", type: 'email' }),
        new Rule({ name: "phone" }),
        new Rule({ name: "tax_regime" }),
        new Rule({ name: "use_cfdi" }),
      ],
      validate: {
        valid: false,
        validations: {
          name: null,
          rfc: null,
          address: null,
          postal_code: null,
          country: null,
          state: null,
          municipality: null,
          suburb: null,
          email: null,
          phone: null,
          tax_regime: null,
          use_cfdi: null,
        },
      },
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("RFC", "rfc"),
        new HeaderGrid("Direccion", "address"),
        new HeaderGrid("CP", "postal_code"),
        new HeaderGrid("Pais", "country"),
        new HeaderGrid("Estado", "state"),
        new HeaderGrid("Municipio", "municipality"),
        new HeaderGrid("Colonia", "suburb"),
        new HeaderGrid("Regimen Fiscal", "tax_regime"),
      ],
      customerHeaders:[
        new HeaderGrid("Nombre","name"),
        new HeaderGrid("RFC","rfc"),
        new HeaderGrid("Dirección","address"),
      ],
      bankAccountHeaders:[
        new HeaderGrid("Banco","name"),
        new HeaderGrid("Clave interbancaria","interbank_key"),
      ],
      loading: false,
    };
  },
  components: { FormDropdownComplex, FormDropdown, DeleteDialog, Loader, FormInputText, BasicFormToolbar, BasicDatatable, HelperDialog },
  created() {
    this.entity = new Customer(this.session);
    this.reference = new CustomerReference(this.session);
    this.bank = new CustomerBankAccount(this.session);
  },
  async mounted() {
    await this.refresh();
  },   
  methods: {
    async openTable(){
      this.entities = await this.entity.all();
      this.open = true;
    },
    openNew() {
      this.entity = new Customer(this.session);
      this.bank = new CustomerBankAccount(this.session);
    },
    async saveCustomer() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);

          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entities = null;
        if (this.open == true){
          this.entities = await this.entity.all();
        }
        this.entity = new Customer(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async saveAccounting() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);

          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entities = null;
        if (this.open == true){
          this.entities = await this.entity.all();
        }
        this.entity = new Customer(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async saveReference() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.reference.id && this.reference.id > 0) {
          //* Actualizamos
          let reference = await this.reference.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', reference);
          //* Modificamos el listado pah
          let index = this.reference.findIndex((x) => x.id == this.reference.id);

          this.references[index] = reference;
        } else {
          //* Creamos uno nuevo
          let reference = await this.reference.save();
          //* Agregamos un dato extra
          this.$emit('save', reference);
          this.references.push(reference);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.references = null;
        if (this.open == true){
          this.references = await this.reference.all();
        }
        this.reference = new CustomerReference(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async saveBankAccount() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.bank.id && this.bank.id > 0) {
          //* Actualizamos
          let bank = await this.bank.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', bank);
          //* Modificamos el listado pah
          let index = this.banks.findIndex((x) => x.id == this.bank.id);

          this.banks[index] = bank;
        } else {
          //* Creamos uno nuevo
          this.bank.id_customer = this.entity.id;
          let bank = await this.bank.save();
          //* Agregamos un dato extra
          this.$emit('save', bank);
          this.banks.push(bank);

          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.bank = new CustomerBankAccount(this.session);
        
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      console.log(this.entity.id);
    },

    editBankAccount(payload) {
      //this.bank = fillObject(this.bank, bank);
      this.bank.id_bank = payload.name;
      this.bank.interbank_key = payload.interbank_key;
      console.log(payload.name);
      
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new Customer(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      this.references = await new CustomerReference(this.session).all();
      try {
        //if (!this.modal) 
        this.c_RegimenFiscal = await new satCatalogo(2);
        this.c_UsoCFDI = await satCatalogo(15);
        this.accounts = await new AccountingLedgerAccount(this.session).all();
        this.references = await new CustomerReference(this.session).all();
        this.entities = await new Customer(this.session).all();
        this.banks = await new Bank(this.session).all();
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    selectCustomer(payload){
      this.entity = fillObject(this.entity, payload);
      this.list();
    },

    async list() {
        this.loading = true;
        try {
          this.customerBanks = await this.bank.data({
            id_customer: this.entity.id
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
  },
  computed: {
    accounting_ledger_account_name() {
      return this.entity.id_accounting_ledger_account ? this.accounts.find(x => this.entity.id_accounting_ledger_account == x.id) ? this.accounts.find(x => this.entity.id_accounting_ledger_account == x.id).name : "" : "";
    }
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

</style>
