import {
    Abstract
} from './Abstract';



export class CustomerReference extends Abstract {
    constructor(session) {
        super('comercial/CustomerReference', session);
        this.name = null;
        this.key_name = null;
    }
}