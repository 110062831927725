import {
    Abstract
} from './Abstract';

export class AccountingLedgerAccount extends Abstract {
    constructor(session) {
        super('accounting/AccountingLedgerAccount', session);
        this.id_accounting_group_type = null;
        this.id_accounting_group = null;
        this.id_accounting_ledger_account_parent = null;
        this.level = null;
        this.id_key = null;
        this.name = null;
        this.key_name = null;
    }
}