import {
    Abstract
} from './Abstract';

export class Customer extends Abstract {
    constructor(session) {
        super('comercial/Customer', session);
        this.name = null;
        this.rfc = null;
        this.address = null;
        this.postal_code = null;
        this.country = "MEXICO";
        this.state = null;
        this.municipality = null;
        this.suburb = null;
        this.email = null;
        this.phone = null;
        this.tax_regime = null;
        this.use_cfdi = null;
        this.id_customer= null;
        this.date=null;    
        this.external_database = null;
        this.external_company = null;
        this.external_branch = null;
        this.income_tpv = 0.00;
        this.total_amount_tpv_note = 0.00;
        this.bw_commercial_business = null;
        this.bw_trade_name = null;
        this.bw_trade_group = null;
        this.bw_transaction_initial_time = null;
        this.bw_transaction_final_time =  null;
    }
}